<template>
  <el-form-item :label="label" :label-width="labelWidth || ''" class="date-picker">
    <div class="container">
      <el-form-item :prop="startProp" style="margin-right: 0;">
        <el-date-picker :type="type" :placeholder="startPlaceholder" v-model="startDateVal"
          style="width: 100%;" :format="format" :value-format="valueFormat" time-arrow-control>
        </el-date-picker>
      </el-form-item>
      <span class="attach">{{attach}}</span>
      <el-form-item :prop="endProp">
        <el-date-picker :type="type" :placeholder="endPlaceholder" v-model="endDateVal" style="width: 100%;"
          :format="format" :value-format="valueFormat" time-arrow-control>
        </el-date-picker>
      </el-form-item>
      <div class="date-btns" style="margin-left: 24px" v-if="showBtn">
        <el-button v-for="(item, index) in btnConfig"
          :class="['date-btn',JSON.stringify([startDateVal,endDateVal])===JSON.stringify(item.time_area)?'btn-active':'']"
          @click="getDateFrame(item.day_ago)" :key="index">{{ item.btnText}}</el-button>
      </div>
    </div>
  </el-form-item>
</template>
<script>
export default {
  name: "DatePeriodPicker",
  props: {
    //label文字
    label: {
      type: String,
    },
    // label宽度
    labelWidth: {
      type: String,
      default: "",
    },
    //开始时间prop
    startProp: {},
    //开始时间文字占位符
    startPlaceholder: {
      default: "开始日期",
    },
    //开始时间绑定的变量
    startTime: {
      type: [Number, String],
      required: true,
    },
    //结束时间prop
    endProp: {},
    //结束时间文字占位符
    endPlaceholder: {
      default: "结束日期",
    },
    //结束时间绑定的变量
    endTime: {
      type: [Number, String],
      required: true,
    },
    //显示类型
    type: {
      default: "date",
    },
    //指定输入框的格式
    format: {
      default: "yyyy-MM-dd",
    },
    //指定绑定值的格式
    valueFormat: {
      default: "timestamp",
    },
    //连接符
    attach: {
      default: "至",
    },
    // 控制右侧按钮显示开关
    showBtn: {
      type: Boolean,
      default: true,
    },
    millisecond: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      btnConfig: [
        { btnText: "今", day_ago: 0, time_area: [] },
        { btnText: "昨", day_ago: 1, time_area: [] },
        { btnText: "近7天", day_ago: 6, time_area: [] },
        { btnText: "近30天", day_ago: 29, time_area: [] },
      ],
    };
  },
  computed: {
    startDateVal: {
      get() {
        let val = "";
        if (this.startTime && this.startTime !== -1) {
          if (!this.millisecond) {
            val = this.startTime * 1000;
          } else {
            val = this.startTime;
          }
        }
        return val;
      },
      set(val) {
        let timestamp = val;
        if (this.valueFormat === "timestamp") {
          if (timestamp) {
            if (!this.millisecond) timestamp = parseInt(timestamp / 1000);
          } else {
            timestamp = -1;
          }
        }
        this.$emit("update:startTime", timestamp);
      },
    },
    endDateVal: {
      get() {
        let val = "";
        if (this.endTime && this.endTime !== -1) {
          if (!this.millisecond) {
            val = this.endTime * 1000;
          } else {
            val = this.endTime;
          }
        }
        return val;
      },
      set(val) {
        let timestamp = val;
        if (this.valueFormat === "timestamp") {
          if (timestamp) {
            if (!this.millisecond) timestamp = parseInt(timestamp / 1000);
          } else {
            timestamp = -1;
          }
        }
        this.$emit("update:endTime", timestamp);
      },
    },
  },
  methods: {
    getDateFrame(day) {
      let nowDate = new Date().toDateString();
      this.startDateVal =
        new Date(nowDate).getTime() - 24 * 60 * 60 * 1000 * day;
      this.endDateVal =
        day === 1
          ? new Date(nowDate).getTime() - 24 * 60 * 60 * 1000
          : new Date(nowDate).getTime();
      this.getTimeArea();
    },
    getTimeArea() {
      let nowDate = new Date().toDateString();
      this.btnConfig.forEach((element) => {
        element.time_area = [
          new Date(nowDate).getTime() - 24 * 60 * 60 * 1000 * element.day_ago,
          element.day_ago === 1
            ? new Date(nowDate).getTime() - 24 * 60 * 60 * 1000
            : new Date(nowDate).getTime(),
        ];
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.date-picker {
  position: relative;
  .container {
    display: flex;
    ::v-deep.el-form-item__content {
      input {
        width: $input-w;
      }
    }
    .el-form-item {
      margin-bottom: 0;
    }
    .attach {
      color: $regularText;
      margin: 0 12px;
    }
  }
}

.date-btn {
  background: #ffffff;
  border-radius: 3px;
  border: 1px solid #d8dce6;
  font-size: 14px;
  color: #c0c4cc;
  padding: 10px;

  &:hover {
    color: #3576ff;
  }
}

.btn-active {
  color: #3576ff;
}

.date-btn + .date-btn {
  margin-left: 12px;
}

.date-btns {
  display: flex;
  flex-wrap: nowrap;
}
</style>
