<template>
  <div class="body">
    <div style="margin-bottom: 27px">
      <list-filter v-model="filterForm" :uploadFilter="ok" />
    </div>
    <div class="list-content">
      <el-table :data="tbody" class="thead-light" stripe style="width: 100%" v-loading="loading"
        @sort-change="sortChange">
        <!-- 操作 -->
        <el-table-column label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="DetailRow(scope.row)">
              查看
            </el-button>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
            :minWidth="th.minWidth || ''" :sortable="th.sortable" :key="index">
            <template slot-scope="scope">
              <!-- 回复状态 -->
              <p v-if="th.prop === 'status_text'" class="replay-status">
                <span :class="
                      scope.row[th.prop] === '已回复' ? 'replayed' : 'no-replay'
                    "></span>
                {{ scope.row[th.prop] }}
              </p>
              <!-- 普通 -->
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
  </div>
</template>

<script>
import listFilter from "../../components/Member/LeaveMsgFilter";
import Pagination from "@/base/components/Default/Pagination";
import { MyAskList } from "../../api/member/member-detail";
export default {
  props: {
    data: {
      type: String,
    },
  },
  data() {
    return {
      loading: false, //加载
      thead: [
        { label: "ID", prop: "id", minWidth: 70 },
        { label: "标题", prop: "title", minWidth: 130 },
        { label: "内容", prop: "content", minWidth: 115 },
        { label: "接收组织", prop: "organization_name", minWidth: 150 },
        { label: "会员名称", prop: "username", minWidth: 100 },
        { label: "会内职务", prop: "user_position", minWidth: 160 },
        { label: "状态", prop: "status_text", minWidth: 80 },
        {
          label: "创建时间",
          prop: "create_time",
          minWidth: 200,
          sortable: true,
        },
      ],
      //筛选对象
      filterForm: {
        id: this.data?this.$deCode(this.data).id:this.$route.params.user_id,
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        status: -1, //回复状态
        keyword: "", //组织名称
        page_size: 15, //每页数据数
      },
      tbody: [],
      pageData: {},
    };
  },
  methods: {
    // 获取留言列表
    getMyAskList(pageData) {
      this.loading = true;
      MyAskList(pageData)
        .then((res) => {
          const { data } = res;
          this.tbody = data.data;
          this.pageData = data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 点击查看
    DetailRow(row) {
      this.$router.push({ name: this.$route.params.user_id ? "UnitLeaveMsgDetail":"MyLeaveMsgDetail", params: { id: row.id } });
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getMyAskList(this.filterForm);
    },
    // 筛选回调
    ok(e) {
      const pageData = {};
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getMyAskList({ ...this.filterForm, ...pageData });
    },
    // 刷新列表
    reflashList() {
      this.getMyAskList({
        page: this.pageData.current_page,
        ...this.filterForm,
      });
    },
    // 分页查询
    changPage(e) {
      console.log(e);
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getMyAskList(pageData);
    },
  },
  created() {
    this.getMyAskList(this.filterForm); //获取列表数据
  },
  components: { listFilter, Pagination },
};
</script>

<style lang="scss" scoped>
.body {
  min-height: calc(100vh - 270px);
  .tool-tip {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .replay-status {
    display: flex;
    align-items: center;

    .replayed {
      display: inline-block;
      height: 6px;
      width: 6px;
      border-radius: 50%;
      margin-right: 5px;
      background: rgb(8, 243, 39);
    }

    .no-replay {
      display: inline-block;
      height: 6px;
      width: 6px;
      border-radius: 50%;
      margin-right: 5px;
      background: rgba(255, 203, 44, 1);
    }
  }
}
</style>
