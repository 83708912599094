<template>
  <div class="pagination" v-if="total > pageSize || total === undefined" :class="{mt:!closeMarginTop}">
    <!-- <div class="page-info">
      共 {{ pageData.total }} 条数据，共
      {{ Math.ceil(pageData.total / pageData.per_page) }} 页
    </div>-->
    <el-pagination v-if="total !== undefined" layout="total, sizes, prev, pager, next, jumper" :current-page="page"
      :page-sizes="pageSizes" :page-size="size" :total="total" background @current-change="changePage"
      @size-change="changeSize" />
    <el-pagination v-else-if="showPrevNext"
                   layout="sizes, prev, next, jumper"
                   :current-page="page" background
                   @current-change="changePage"
                   @size-change="changeSize"
                   :page-size="pageSize" :page-sizes="pageSizes" />
  </div>
</template>
<script>
export default {
  props: {
    //分页数据（已过时，请使用 total、page）
    pageData: Object,
    //总数据
    total: {
      type: Number,
    },
    //第几页，sync绑定
    page: {
      type: Number,
    },
    //第几页回调函数
    change: {
      type: Function,
    },
    //每页显示多少条
    pageSize: {
      type: Number,
      default: 15,
    },
    //关闭顶部外边距
    closeMarginTop: {
      type: Boolean,
      default: false,
    },
    /* 适配没有 total 字段的列表 */
    showPrevNext: Boolean,
    enableAll: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      size: 15, // 组件本身记录的每页多少条
    };
  },
  computed: {
    //每页显示多少条的数组
    pageSizes() {
      const baseArr = [1, 2, 3, 4].map((it) => it * this.pageSize)
      if (this.enableAll) baseArr.push(this.total)
      return baseArr
    },
  },
  methods: {
    //分页变化
    changePage(val) {
      if (this.change) {
        this.change({ page: val, page_size: this.size });
      } else {
        this.$emit("update:page", val);
      }
    },
    //分页数量变化
    changeSize(val) {
      this.size = val;
      const changeEndPage = Math.ceil(this.total / val);
      if (changeEndPage < this.page) return;
      if (this.change) {
        this.change({ page: this.page, page_size: this.size });
      } else {
        this.$emit("update:pageSize", val);
      }
    },
  },
  created() {
    this.size = this.pageSize;
    if (this.pageData) {
      console.log("Pagination 的 pageDate 参数已过时，请尽快适配！");
      this.total = this.pageData.total;
      this.page = this.pageData.current_page;
    }
  },
};
</script>
<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  &.mt {
    margin-top: 40px;
  }
  .page-info {
    color: $text-muted;
  }
  // ::v-deep.el-pagination {
  //   li,
  //   button,
  //   span {
  //     min-width: 42px;
  //     height: 42px;
  //     line-height: 42px;
  //     border: 1px solid $border-color;
  //     border-radius: 4px;
  //   }
  //   li {
  //     margin-left: 8px;
  //     &:last-child {
  //       margin-right: 8px;
  //     }
  //   }
  //   span {
  //     border: none;
  //   }
  // }
}
</style>
