<template>
  <div class="layout-filter">
    <!-- 折叠内容 -->
    <div class="layout-filter-collapse" :style="{height:toggleCollapseEnable ? isCollapse ? collapseHeight+'px' : fullHeight : ''}" :ref="'layoutFilterCollapse-' + id">
      <div class="layout-filter-container" :ref="'layoutFilterContainer-' + id">
        <slot></slot>
      </div>
    </div>
    <!-- 按钮 -->
    <div class="layout-filter-btn" :style="{'marginLeft':labelWidth}">
      <el-button v-if="onFilter" size="small" type="primary" :loading="isFiltering"
        @click="onClick('filter')">筛选
      </el-button>
      <el-button v-if="onExport" size="small" :loading="isExporting" @click="onClick('export')">导出</el-button>
      <el-button :loading="isExporting" @click="onClick('attachment')" size="small" v-if="onAttachment">导出附件</el-button>
      <el-button v-if="toggleCollapseEnable" size="small" type="text" @click="toggleCollapse">
        {{isCollapse?'展开筛选':'收起筛选'}}
        <i :class="!isCollapse?'el-icon-arrow-up':'el-icon-arrow-down'"></i>
      </el-button>
      <el-button v-if="onReset" size="small" type="text" :loading="isResetting" @click="onClick('reset')">
        重置筛选条件
      </el-button>
    </div>
    <slot :label-width="labelWidth" name="footer"></slot>
  </div>
</template>
<script>
export default {
  props: {
    id: String,
    //label宽度，控制底部按钮的 marginLeft
    labelWidth: {
      type: String,
      default: "70px",
    },
    //筛选事件
    onFilter: {
      type: Function,
    },
    //导出事件
    onExport: {
      type: Function,
    },
    // 导出附件 2024年08月26日12:15:09
    onAttachment: {
      type: Function,
    },
    //重置事件
    onReset: {
      type: Function,
    },
    //折叠后的高度 px
    collapseHeight: {
      type: [String, Number],
      default: 120,
    },
    //默认是否折叠
    defaultCollapse: {
      type: Boolean,
      default: true,
    },
    //开启展开筛选按钮
    toggleCollapseEnable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isCollapse: this.defaultCollapse ?? false,
      isFiltering: false,
      isExporting: false,
      isResetting: false,
      fullHeight: '' // 展开后的高度，自动读取插槽内容高度
    };
  },
  watch: {
    toggleCollapseEnable() {
      if (this.isCollapse) {
        /* 折叠状态 */
      } else {
        /* 展开状态 */
        this.toggleCollapse()
      }
    }
  },
  methods: {
    async onClick(key) {
      switch (key) {
        case "filter":
          this.isFiltering = true;
          await this.onFilter();
          this.isFiltering = false;
          break;
        case "export":
          this.isExporting = true;
          await this.onExport();
          this.isExporting = false;
          break;
        case 'attachment':
          this.isExporting = true;
          await this.onAttachment();
          this.isExporting = false;
          break
        case "reset":
          this.isResetting = true;
          await this.onReset();
          this.isResetting = false;
          break;
      }
    },
    //切换展开/折叠状态
    toggleCollapse() {
      this.isCollapse = !this.isCollapse;
      this.updateCollapse();
    },
    //更新展开/折叠状态
    updateCollapse() {
      if (this.isCollapse) {
        this.$refs["layoutFilterCollapse-" + this.id].style.height =
          this.collapseHeight + "px";
      } else {
        this.$refs["layoutFilterCollapse-" + this.id].style.height =
          this.$refs["layoutFilterContainer-" + this.id].scrollHeight + "px";

        // 上面的写法修改高度不生效，那么就用这个 fullHeight
        this.fullHeight = this.$refs["layoutFilterContainer-" + this.id].scrollHeight + "px"
      }
    },
    //重置折叠状态
    reduction(collapse) {
      if (typeof collapse !== "undefined") {
        this.isCollapse = collapse;
      } else {
        this.isCollapse = this.defaultCollapse;
      }
      this.updateCollapse();
    },
  },
  created() {
    //适配默认展开
    if (!this.isCollapse) {
      this.$nextTick(() => {
        this.updateCollapse();
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.layout-filter {
  overflow-x: auto;
  background: #f2f3f5;
  padding: 24px;

  .layout-filter-collapse {
    min-width: 800px;
    width: fit-content;
    overflow: hidden;
    transition: height 0.3s;
    position: relative;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);

    .layout-filter-container {
      overflow: hidden; //重要：bfc
    }
  }

  .layout-filter-btn {
    button {
      & + button {
        margin-left: 20px;
      }
    }
  }
}
</style>
